import * as React from 'react';
import { graphql } from 'gatsby';
import { useMobile } from '../../../utils/use-series';

import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CLabelList,
  CSectTitle,
  CSpSliderCard,
  CFadeSlider,
  CFixedImg,
  CSimpleCard,
  LBanquetIncs,
  LVenue,
  LWifi,
  LBanquetContact,
  CSpSliderCard03,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: '小宴会場「カメリア」',
            sub: '宴会棟 2F',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/camellia/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/camellia/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: '小宴会場「カメリア」',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap>
          <h2 className="c_headingLv2">小宴会場「カメリア」</h2>
          <CLabelList
            data={[
              'パーティー',
              '会議・コンベンション',
              '講演・セミナー',
              'イベント・展示',
              '同窓会',
            ]}
          />
            <CSpSliderCard03
              exClass="u_mb80"
              col={2}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/camellia/img_camellia.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/camellia/img_camellia02.png',
                    alt: '',
                  },
                },
              ]}
            />
          <p className="c_sectLead u_mb30">
            バーカウンターを備えた前室から、ヨーロッパの邸宅を想像させる小宴会場「カメリア」。
            <br />
            気品あるクラシックモダンのインテリア、格調高い調度品や絵画が心地よい時間をお約束いたします。
          </p>
          {!isSp ? (
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th rowSpan={2}>広さ（㎡）</th>
                  <th colSpan={4}>人数</th>
                </tr>
                <tr>
                  <th>正餐</th>
                  <th>立食</th>
                  <th>スクール</th>
                  <th>シアター</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>117</td>
                  <td>50</td>
                  <td>50</td>
                  <td>54</td>
                  <td>70</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th>広さ（㎡）</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>117</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={2}>人数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>正餐</th>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th>立食</th>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th>スクール</th>
                    <td>54</td>
                  </tr>
                  <tr>
                    <th>シアター</th>
                    <td>70</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <CFixedImg
            exClass="u_mbLarge"
            width={1040}
            img={{
              src: '/assets/images/banquet/camellia/img_camellia03.png',
              alt: '',
            }}
          />
          <CSectTitle
            title={{
              ja: 'レイアウト例',
              en: (
                <>
                  LAYOUT <br className="u_sp" />
                  EXAMPLE
                </>
              ),
            }}
          />
          <CSimpleCard
            data={[
              {
                img: {
                  src: '/assets/images/banquet/camellia/img_camellia04.png',
                  alt: '',
                },
                text: <>正餐 50名</>,
              },
              {
                img: {
                  src: '/assets/images/banquet/camellia/img_camellia05.png',
                  alt: '',
                },
                text: <>スクール 54名</>,
              },
            ]}
          />
        </LWrap>
      </section>
      <LBanquetIncs />
      <LVenue />
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
